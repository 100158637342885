<template>
  <div class="tingXiaZDetail">
    <div class="infoBox">
      <div class="infoBox-left">
        <div class="img">
          <img :src="postData.topicPicture" alt="" />
        </div>
      </div>
      <div class="infoBox-right">
        <div class="title">{{ postData.topicTitle || "" }}</div>
        <div class="pro">{{ postData.topicTitle || "" }}</div>
      </div>
    </div>
    <div class="tingXiaZDetail-b">
      <v-h5Mtml :content="postData.topicContent"></v-h5Mtml>
    </div>
  </div>
</template>

<script>
import { getTopicInfoByIdURL } from "../api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  name: "tingXiaZDetail",
  data() {
    return {
      id: "",
      oIndex: 1,
      postData: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    Selection(data) {
      this.oIndex = data;
    },
    async getInfo(id) {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getTopicInfoByIdURL}`, {
        params: params,
      });
      if (res.code == 200) {
        this.postData = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tingXiaZDetail {
  min-height: 100vh;
  padding: 18px 32px;
  .infoBox {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    .infoBox-right {
      .title {
        width: 100%;
        font-size: 30px;
        font-weight: 600;
        color: #323334;
        line-height: 52px;
        margin-top: 8px;
      }
      .pro {
        font-size: 24px;
        color: #323334;
        line-height: 32px;
        margin-top: 8px;
      }
    }
    .infoBox-left {
      .img {
        width: 192px;
        height: 192px;
        border-radius: 16px;
        margin-right: 24px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
